import React from "react";
import firebase from "firebase";
import { orderBy } from "lodash";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";

// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";

// Components
import ProjectDocumentTemplates from "../../components/project-templates/project-document-templates";
import SaveAsTemplateDialog from "./partials/document-save-as-template-dialog";
import AddSectionDialog from "./partials/document-add-section-dialog";
import RoleAccess from "../../components/role-access/role-access";
import CustomCard from "../../components/custom-card/custom-card";
import DocumentActionMenu from "./partials/document-action-menu";
import StateCard from "../../components/state-card/state-card";
import DocumentTemplates from "./partials/document-templates";
import Title from "../../components/title/title";

// Hooks
import useStoreProvider from "../../common/providers/store/use-app-context";
import useFetchCollection from "../../common/utils/use-fetch-collection";
import useFetchDocument from "../../common/utils/use-fetch-document-v2";

// Utilities and constants
import { copyDocument } from "../../common/utils/copy-document";
import { db, USER_ROLES } from "../../constants";
import messages from "../../i18n/messages";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    placeContent: "space-between",
    alignItems: "center",
  },
}));

const ProjectDocuments = () => {
  const intl = useIntl();
  const batch = db.batch();
  const { pid } = useParams();
  const classes = useStyles();
  const { getCollection } = useFetchCollection();
  const { data: documentSectionData, getDocument: fetchDocumentSection } = useFetchDocument();
  const {
    data: projectData,
    fetching: fetchingProjectDetail,
    getDocument: fetchProjectDetail,
  } = useFetchDocument();

  const { loggedInUser, selectedProject, setSelectedProject } = useStoreProvider();

  const [fetching, setFetching] = React.useState(true);
  const [documentSections, setDocumentSections] = React.useState([]);
  const [projectDocuments, setProjectDocuments] = React.useState([]);
  const [selectedDocumentTemplateId, setSelectedDocumentTemplateId] = React.useState(null);
  const [addSectionDailogState, setAddSectionDailogState] = React.useState(false);
  const [saveAsTemplateDialogState, setSaveAsTemplateDialogState] = React.useState(false);

  const skeletonArray = Array(5).fill("");
  const documentsCollectionRef = db
    .collection("projects")
    .doc(pid)
    .collection("documents")
    .orderBy("createdAt", "desc")
    .where("status", "==", "active");

  const breadcrumbsNav = [
    { label: "Projects", to: "/" },
    { label: selectedProject?.projectName, to: `/projects/${pid}` },
    { label: "Documents", to: `/projects/${pid}/weld-logs/documents` },
  ];

  // Delete document section. When a document section is deleted, it will be excluded
  // from report generation since it will not be included in the section selection
  const handleDocumentSectionDelete = async (sectionKey, sectionId) => {
    const documentTemplateId = selectedProject?.documentTemplate;
    const sectionsDocumentRef = db
      .collection("projects")
      .doc(pid)
      .collection("document-sections")
      .doc(documentTemplateId);

    const deletedSectionDocumentsRef = db
      .collection("projects")
      .doc(pid)
      .collection("documents")
      .where("section", "==", sectionId);

    try {
      await sectionsDocumentRef.set(
        {
          documentSections: {
            [sectionKey]: firebase.firestore.FieldValue.delete(),
          },
        },
        { merge: true }
      );

      // Get the list of updated sections
      if (!documentSectionData) {
        const updatedDocumentSections = await fetchDocumentSection(sectionsDocumentRef);
        setDocumentSections(updatedDocumentSections);
      }

      // Delete documents that were under the deleted sections
      const documentsOfDeletedSection = await getCollection(deletedSectionDocumentsRef);

      documentsOfDeletedSection.docs?.map(async (doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
    } catch (error) {
      console.log("handleDocumentSectionDelete::", error);
    }
  };

  const getSelectedDocumentTemplate = async () => {
    // Fetch project details only if empty
    if (!selectedProject?.documentTemplate) {
      const projectDocumentRef = db.collection("projects").doc(pid);
      const projectData = await fetchProjectDetail(projectDocumentRef);
      setSelectedProject(projectData);
    }

    /* 
      TemplateId of current project. It could be fetched in 3 ways
      1. From selection menu if the project document section is being created
      2. From selectedProject object saved on context
      3. On refresh, project details is fetched and templateId is updated
    */
    const documentTemplateId =
      selectedDocumentTemplateId ||
      selectedProject?.documentTemplate ||
      projectData?.documentTemplate;

    if (!documentTemplateId) {
      setDocumentSections(null);
      setFetching(false);
      return;
    }

    const document = await db
      .collection("projects")
      .doc(pid)
      .collection("document-sections")
      .doc(documentTemplateId)
      .get();

    const templateDocument = document.data();
    setDocumentSections(templateDocument);
    setFetching(false);
  };

  // When a project is created and document template is chosen, project
  // is updated with the type of document template that is taken into use.
  const updateProjectWithSelectedTemplateId = async () => {
    await db.collection("projects").doc(pid).set(
      {
        documentTemplate: selectedDocumentTemplateId,
      },
      { merge: true }
    );
  };

  // Copy template document from project-templates to document-sections
  // of a project.
  const addSelectedDocumentTemplateToProject = async () => {
    const fromDocument = db
      .collection("project-templates")
      .doc("customs")
      .collection("english")
      .doc(selectedDocumentTemplateId);

    const toDocument = db
      .collection("projects")
      .doc(pid)
      .collection("document-sections")
      .doc(selectedDocumentTemplateId);

    await copyDocument(fromDocument, toDocument);
    await updateProjectWithSelectedTemplateId();
    await getSelectedDocumentTemplate();
  };

  const getProjectDocuments = (querySnapshot) => {
    let projectDoc = [];
    querySnapshot.forEach((doc) => {
      const {
        id,
        createdAt,
        createdBy,
        filename,
        section,
        position,
        storageRef,
        thumbnailStorageRef,
      } = doc.data();

      projectDoc.push({
        id,
        section,
        position,
        createdAt,
        createdBy,
        filename,
        storageRef,
        thumbnailStorageRef,
      });
    });
    const orderedDocuments = orderBy(projectDoc, ["createdAt"], ["desc"]);
    setProjectDocuments(orderedDocuments);
  };

  const addHeaderToTemplate = (headerName) => {
    if (!headerName) return;

    const sectionsLength = Object.values(documentSections?.documentSections).length;
    const position = sectionsLength + 1;
    const key = `custom-header-${position}`;
    db.collection("projects")
      .doc(pid)
      .collection("document-sections")
      .doc(selectedProject?.documentTemplate)
      .set(
        {
          documentSections: {
            [key]: { name: headerName, id: position, position: position },
          },
        },
        { merge: true }
      );
    getSelectedDocumentTemplate();
  };

  const saveAsTemplate = (templateName) => {
    if (!templateName) return;

    const templateDocumentId = db
      .collection("projects")
      .doc(pid)
      .collection("document-sections")
      .doc().id;

    db.collection("project-templates")
      .doc("customs")
      .collection("english")
      .doc(templateDocumentId)
      .set({
        ...documentSections,
        id: templateDocumentId,
        templateName: templateName,
        createdBy: `${loggedInUser?.fname} ${loggedInUser?.lname}`,
        createdByUid: loggedInUser?.uid,
        createdAt: firebase.firestore.Timestamp.now(),
      });
  };

  const renderEmptyStateCard = () => {
    const buttonProps = {
      color: "primary",
      variant: "contained",
      label: intl.formatMessage(messages.useTemplate),
      onClick: () => {
        addSelectedDocumentTemplateToProject();
      },
      disabled: !Boolean(selectedDocumentTemplateId),
    };

    return (
      <StateCard
        title={intl.formatMessage(messages.noDocumentsYet)}
        description={intl.formatMessage(messages.pleaseSelectTheDocument)}
        buttonProps={buttonProps}
        children={
          <DocumentTemplates
            selectedTemplate={selectedDocumentTemplateId}
            setSelectedDocumentTemplateId={setSelectedDocumentTemplateId}
          />
        }
      />
    );
  };

  const handleClickAddSectionItem = () => {
    setAddSectionDailogState(true);
  };

  const handleAddSection = (value) => {
    setAddSectionDailogState(false);
    addHeaderToTemplate(value);
  };

  const handleSaveAsSectionItemClick = () => {
    setSaveAsTemplateDialogState(true);
  };

  const handleSaveAsTemplate = (value) => {
    setSaveAsTemplateDialogState(false);
    saveAsTemplate(value);
  };

  React.useEffect(() => {
    getSelectedDocumentTemplate();
    documentsCollectionRef.onSnapshot(getProjectDocuments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData]);

  return (
    <>
      <Box width="100%">
        {documentSections ? (
          <>
            <Title
              title={intl.formatMessage(messages.documents)}
              breadcrumbsNav={breadcrumbsNav}
              fetchingProjectDetail={fetchingProjectDetail}
            />
            <CustomCard
              header={
                <Box className={classes.header}>
                  <Typography variant="subtitle1">{documentSections?.templateName}</Typography>
                  <RoleAccess
                    roles={[
                      USER_ROLES.ADMIN,
                      USER_ROLES.PROJECT_LEADER,
                      USER_ROLES.WELDING_COORDINATOR,
                      USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
                    ]}
                  >
                    <DocumentActionMenu
                      handleClickAddSectionItem={handleClickAddSectionItem}
                      handleSaveAsSectionItemClick={handleSaveAsSectionItemClick}
                    />
                  </RoleAccess>
                </Box>
              }
            >
              <Box>
                {fetching
                  ? skeletonArray?.map((_value, index) => (
                      <Skeleton animation="wave" width="100%" height={50} key={index} />
                    ))
                  : Object.keys(documentSections?.documentSections)
                      .sort(
                        (a, b) =>
                          documentSections?.documentSections[a].position -
                          documentSections?.documentSections[b].position
                      )
                      .map((sectionKey, index) => {
                        const docForSection = projectDocuments.filter((doc) => {
                          return doc.section === documentSections?.documentSections[sectionKey].id;
                        });
                        return (
                          <ProjectDocumentTemplates
                            key={index}
                            section={documentSections?.documentSections[sectionKey]}
                            sectionKey={sectionKey}
                            sectionNumber={index + 1}
                            docForSection={docForSection}
                            sectionId={documentSections?.documentSections[sectionKey].id}
                            handleDocumentSectionDelete={handleDocumentSectionDelete}
                          />
                        );
                      })}
              </Box>
              <Box mt={4}>
                <RoleAccess
                  roles={[
                    USER_ROLES.ADMIN,
                    USER_ROLES.PROJECT_LEADER,
                    USER_ROLES.WELDING_COORDINATOR,
                    USER_ROLES.RESPONSIBLE_WELDING_COORDINATOR,
                  ]}
                >
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    onClick={() => handleClickAddSectionItem()}
                  >
                    {intl.formatMessage(messages.addSection)}
                  </Button>
                </RoleAccess>
              </Box>
            </CustomCard>
            <AddSectionDialog open={addSectionDailogState} onClose={handleAddSection} />

            <SaveAsTemplateDialog open={saveAsTemplateDialogState} onClose={handleSaveAsTemplate} />
          </>
        ) : (
          renderEmptyStateCard()
        )}
      </Box>
    </>
  );
};

ProjectDocuments.propTypes = {};

export default ProjectDocuments;
